// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// 'License'); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an 'AS IS' BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import "./ace/ace";
import "./ace/ext-language_tools";
import "./ace/ext-searchbox";
import "./ace/ext-settings_menu";
import "./ace/mode-bigquery";
import "./ace/mode-dasksql";
import "./ace/mode-druid";
import "./ace/mode-elasticsearch";
import "./ace/mode-flink";
import "./ace/mode-hive";
import "./ace/mode-hplsql";
import "./ace/mode-impala";
import "./ace/mode-ksql";
import "./ace/mode-markdown";
import "./ace/mode-mysql";
import "./ace/mode-pgsql";
import "./ace/mode-phoenix";
import "./ace/mode-presto";
import "./ace/mode-solr";
import "./ace/mode-sparksql";
import "./ace/mode-sql";
import "./ace/mode-text";
import "./ace/snippets/bigquery";
import "./ace/snippets/druid";
import "./ace/snippets/elasticsearch";
import "./ace/snippets/flink";
import "./ace/snippets/hive";
import "./ace/snippets/hplsql";
import "./ace/snippets/impala";
import "./ace/snippets/ksql";
import "./ace/snippets/markdown";
import "./ace/snippets/mysql";
import "./ace/snippets/pgsql";
import "./ace/snippets/phoenix";
import "./ace/snippets/presto";
import "./ace/snippets/dasksql";
import "./ace/snippets/solr";
import "./ace/snippets/sql";
import "./ace/snippets/text";
import "./ace/theme-hue";
import "./ace/theme-hue_dark";
import "./aceExtensions";
export default window.ace;
var DIALECT_ACE_MODE_MAPPING = {
  'bigquery': 'ace/mode/bigquery',
  'druid': 'ace/mode/druid',
  'elasticsearch': 'ace/mode/elasticsearch',
  'flink': 'ace/mode/flink',
  'dasksql': 'ace/mode/dasksql',
  'hive': 'ace/mode/hive',
  'hplsql': 'ace/mode/hplsql',
  'impala': 'ace/mode/impala',
  'ksql': 'ace/mode/ksql',
  'mysql': 'ace/mode/mysql',
  'pgsq': 'ace/mode/pgsql',
  'phoenix': 'ace/mode/phoenix',
  'presto': 'ace/mode/presto',
  'solr': 'ace/mode/solr',
  'sql': 'ace/mode/sql'
};
export var getAceMode = function getAceMode(dialect) {
  return dialect && DIALECT_ACE_MODE_MAPPING[dialect] || DIALECT_ACE_MODE_MAPPING.sql;
};